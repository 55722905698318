// @mui
// components
import { GridColumns } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { sentenceCase } from 'change-case';
// mock
import { useEffect } from 'react';
import { MoreMenuReusable } from '../../components/Common/MoreMenuReusable';
import Label from '../../components/Label';
import { MainPageWithTable } from '../../components/Common/PageWraper/mainPageWithTable';
import { API_URL, UrlConfig, useFetch } from '../../hooks/useFetchHooksi';
import { CompanyMainInfo } from './Types/CompanyMainInfo';
import { useTableHook } from '../../hooks/useTableHook';
import { fromIsoStringToYearMonthDayHouerMinuteSecond } from '../../utils/dateUtil';
import { RenderContentOrLoadingSpinner } from '../../components/Common/LoadingStateSpinner/RenderContentOrLoadingSpinner';
import { fromStatusBooleanToDescription } from './Helpers/CompanyDisplayHelpers';
import { fromNumberStatusToDescriptionEndColor } from './StatusHelper';

// ----------------------------------------------------------------------

export default function MainCompaniesPage() {
  const navigate = useNavigate();
  const fetchConfig: UrlConfig<any> = {
    url: API_URL.ORDER_LIST,
    params: undefined,
  };

  const {
    tableData,
    setTableData,
    handleUpdateItemClick,
    handleDeleteItemClick,
    handleDetailsClick,
  } = useTableHook<CompanyMainInfo>(
    API_URL.ORDER_LIST,
    '/orders/addGift',
    '/dashboard/giftDetails'
  );
  const { data: fetchedData, loading, setLoading } = useFetch<CompanyMainInfo[]>(fetchConfig);

  useEffect(() => {
    if (fetchedData) {
      // const companiesTableData = fetchedData.map((company) => fromCompanyFetchedToCompanyDisplay(company));
      setTableData(fetchedData);
    } else {
      setTableData([]);
    }
  }, [fetchedData]);

  const TABLE_COLS_DATAGRID: GridColumns<CompanyMainInfo> = [
    {
      field: 'dateCreate',
      headerName: 'Data dodania',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      renderCell: (params) => <> {fromIsoStringToYearMonthDayHouerMinuteSecond(params.value)}</>,
    },
    {
      field: 'name',
      headerName: 'Imię',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
    },
    {
      field: 'email',
      headerName: 'Adres e-mail',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      filterable: false,
      renderCell: (params /* @ts-ignore */) => {
        const status = fromNumberStatusToDescriptionEndColor(params.value);
        return (
          // @ts-ignore
          <Label color={status.color}>{sentenceCase(status.description)}</Label>
        );
      },
    },
    {
      field: ' ',
      headerName: '' /* flex: 1, */,
      align: 'center',
      minWidth: 50,
      renderCell: (params) => {
        // const rowIndex = tableData.findIndex((row) => row.id === params.id);
        const rowIndex = tableData?.findIndex((row) => row.id === params.id);
        return (
          <MoreMenuReusable
            itemToEditOrDeleteTableIndex={rowIndex}
            deleteItem={handleDeleteItemClick}
            deleteTitle={'Anuluj'}
            // editItem={handleUpdateItemClick}
            // editTitle={'Ustaw numer listu przewozowego'}
            detailsRedirect={handleDetailsClick}
            /* backupListRedirect={handleBackupListRedirect}
                        actualizationRedirect={handleActualizationRedirect} */
          />
        );
      },
    },
  ];

  return (
    <RenderContentOrLoadingSpinner isLoading={loading}>
      <MainPageWithTable
        firstCellSortOrder={'desc'}
        pageHelmetTitle={'Gifts'}
        pageTitle={'Zamówienia'}
        buttonLabel={'Dodaj zaproszenie'}
        formPageUrl={'/dashboard/AddGift'}
        fetchDataUrl={API_URL.ORDER_LIST}
        gridColumns={TABLE_COLS_DATAGRID}
        tableData={tableData}
      />
    </RenderContentOrLoadingSpinner>
  );
}
