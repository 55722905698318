import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from './useFetchHooksi';
import { useDeleteHook } from './useDeleteHook';
import { CompanyMainInfo } from '../pages/Companies/Types/CompanyMainInfo';

export interface DeleteProcessState {
  deletedId: string | null;
  deleting: boolean;
}

export interface DbTable {
  id?: Number;
}

export interface AddOrUpdateState {
  itemToEditData: any | null;
}

export function useTableHook<T extends DbTable>(
  backendUrl: API_URL,
  addOrUpdatePageUrl?: string,
  detailsPageUrl?: string,
  initialTableData?: T[] | null
) {
  const [tableData, setTableData] = useState<T[] | null>(initialTableData || []); // initialization will not work if data are fetched async. In this case setter and use effect must be used
  const { deleteProcessState, setDeleteProcessState, deleteSuccessfull } = useDeleteHook<any>({
    url: backendUrl,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const handleDeleteItem = (): void => {
      if (deleteSuccessfull && deleteProcessState.deletedId) {
        if (tableData) {
          if (backendUrl === API_URL.ORDER) {
            // hande delete for companies where there is no real delete but only change of active status
            const tableDataWithChangedActiveStatus = (
              tableData as unknown as CompanyMainInfo[]
            ).map((item) => {
              if (item.id === Number(deleteProcessState.deletedId)) {
                item.isActive = false;
              }
              return item;
            });
            setTableData(tableDataWithChangedActiveStatus as unknown as T[]);
            setDeleteProcessState({ deletedId: null, deleting: false });
          } else {
            // handle delete for other tables where there is real delete
            const tableDataWithoutRemovedItem = tableData.filter(
              (item) => item.id !== Number(deleteProcessState.deletedId)
            );
            setTableData(tableDataWithoutRemovedItem);
            setDeleteProcessState({ deletedId: null, deleting: false });
          }
        }
      }
    };
    handleDeleteItem();
  }, [deleteSuccessfull, deleteProcessState.deletedId, tableData, backendUrl]);

  const handleDeleteItemClick = useCallback(
    (index: number): void => {
      const id = tableData && tableData[index].id ? String(tableData[index].id) : null;
      setDeleteProcessState({
        deletedId: id,
        deleting: true,
      });
    },
    [tableData]
  );

  const handleUpdateItemClick = useCallback(
    // alternative logic can be used to open a modal instead of navigate to another page
    (index: number): void => {
      if (tableData && addOrUpdatePageUrl) {
        navigate(addOrUpdatePageUrl, { state: tableData[index] });
        /* 
        * to navigate to new tab use analogical to this:
        * 
        *   const id= tableData && tableData[index].id ? String(tableData[index].id) : null;
        navigate(`${addOrUpdatePage}/${id}`, { state: tableData[index] });
        * and adjust routing in routes.js 
        * 
        * */
      }
    },
    [tableData, addOrUpdatePageUrl]
  );

  const handleDetailsClick = useCallback(
    (index: number): void => {
      if (tableData && detailsPageUrl) {
        const id = tableData && tableData[index].id ? String(tableData[index].id) : null;
        navigate(`${detailsPageUrl}/${id}`, { state: tableData[index] });
      }
    },
    [tableData, detailsPageUrl]
  );

  return {
    tableData,
    setTableData,
    setDeleteProcessState,
    deleteProcessState,
    handleDetailsClick,
    handleDeleteItemClick,
    handleUpdateItemClick,
  };
}
