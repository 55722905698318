import { useLocation, useParams, useNavigate, NavLink } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import { PageWrapper } from '../../../components/Common/PageWraper/pageWrapper';
import { CompanyMainInfo } from '../Types/CompanyMainInfo';
import { API_URL, UrlConfig, useFetch } from '../../../hooks/useFetchHooksi';
import { Company } from '../../../Dtos/company';
import { Update } from '../../../Dtos/update';
import { Backup } from '../../../Dtos/backup';
import { ProductItem } from '../../../Dtos/productItem';
import { OrderData } from '../../../Dtos/orderData';
import { ActualizationsList } from '../../../components/Companies/Actualizations/actualizations';
import { BackupList } from '../../../components/Companies/BackupList/backupList';
import { Version } from '../../../Dtos/version';
import { VersionList } from '../../../components/Companies/VersionsList/versionsList';
import { Box, Divider, Grid, Paper, Button } from '@mui/material';

import SendIcon from '@mui/icons-material/Send';
import {
  RenderKeyValueList,
  RenderKeyValueProps,
} from '../../../components/Common/renderKeyValuePair';
import { DetailPageSection } from '../../../components/Common/DetailPageCommonComponents/DetailPageSection';
import { fromIsoStringToYearMonthDayHouerMinuteSecond } from '../../../utils/dateUtil';
import { RenderContentOrLoadingSpinner } from '../../../components/Common/LoadingStateSpinner/RenderContentOrLoadingSpinner';
import { OrderToEdit } from 'src/components/Companies/AddEditCompanyForm/SetTrackingCodeForm';

export const CompanyDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const companyState = location.state as CompanyMainInfo;
  const { id } = useParams();
  const fetchConfig: UrlConfig<any> = {
    url: API_URL.ORDER,
    params: undefined,
    id: String(id),
  };
  
  const [actualizationData, setActualizationData] = useState<Update[] | null>(null);
  const [backupData, setBackupData] = useState<ProductItem[] | null>([]);
  const [versionData, setVersionData] = useState<Version[] | null>([]);
  const { data: CompanyData, loading, setLoading } = useFetch<Company>(fetchConfig);
  const { state } = location as any;
  const orderToEdit: OrderToEdit = 
     {
        orderId: CompanyData?.code,
        trackingCode: CompanyData?.trackingCode
      }
    ;
  const companyDetailsTitle = `Szczegóły`;
  useEffect(() => {
    if (CompanyData) {
      const updates = CompanyData.updates ? CompanyData.updates : [];
      const backups = CompanyData.products ? CompanyData.products : [];
      const versions = CompanyData.history ? CompanyData.history : [];
      setActualizationData(updates);
      setBackupData(backups);
      setVersionData(versions);
    } else {
      setActualizationData(null);
      setBackupData(null);
      setVersionData(null);
    }
  }, [CompanyData]);

  const shippingSectionData: RenderKeyValueProps[] = [
    {
      name: 'Imię i nazwisko',
      value: CompanyData?.shipping?.name,
    },
    {
      name: 'Telefon',
      value: CompanyData?.shipping?.phone,
    },
    {
      name: 'Adres 1:',
      value: CompanyData?.shipping?.address,
    },
    {
      name: 'Adres 2:',
      value: CompanyData?.shipping?.address_l2,
    },
    {
      name: 'Kod:',
      value: CompanyData?.shipping?.zipCode,
    },
    {
      name: 'Miasto:',
      value: CompanyData?.shipping?.city,
    },
    {
      name: 'Państwo:',
      value: CompanyData?.shipping?.country,
    },
    {
      name: 'Punk odbioru:',
      value: CompanyData?.shipping?.companyPointName,
    },
    {
      name: 'Paczkomat:',
      value: CompanyData?.shipping?.inPostName,
    },
  ]
  const detailsSectionData: RenderKeyValueProps[] = [
    {
      name: 'Imię',
      value: CompanyData?.name,
    },
    {
      name: 'Kod',
      value: CompanyData?.code,
    },
    {
      name: 'Adres email',
      value: CompanyData?.email,
    },
    {
      name: 'Numer telefonu',
      value: CompanyData?.order?.phone,
    },
    {
      name: 'Numer listu przewozowego',
      value: CompanyData?.trackingCode,
    },
  ];
  return (
    <PageWrapper pageHelmetTitle={`GiftPack/${id}`}>
      <RenderContentOrLoadingSpinner isLoading={loading}>
        <>
        
          <DetailPageSection title={companyDetailsTitle}>
            <RenderKeyValueList items={detailsSectionData} />
          </DetailPageSection>
          <DetailPageSection title="Dostawa">
            <RenderKeyValueList items={shippingSectionData} />
          </DetailPageSection>
                <NavLink to={`/dashboard/setTrackGift`} state={{
        orderId: CompanyData?.code,
        trackingCode: CompanyData?.trackingCode
      }}>
              Ustaw numer listu przewozowego
            </NavLink>
          {/* {actualizationData && (
            <ActualizationsList tableData={actualizationData} companyId={CompanyData?.id} />
          )} */}
          {backupData && <BackupList tableData={backupData} />}
          {versionData && <VersionList tableData={versionData} />}
        </>
      </RenderContentOrLoadingSpinner>
    </PageWrapper>
  );
};
