// config
import axios, { AxiosError, AxiosResponse } from 'axios';
import { ErrorToast, uiActions } from '../Store/ui-slice';
import store from '../Store';

// ----------------------------------------------------------------------
const connectionErrorIsAlreadyDisplayed = (errorMessage: string | undefined): boolean => {
  let testResult = false;
  if (errorMessage) {
    if (errorMessage.includes('Network Error') || errorMessage.includes('NotAuthorized')) {
      const errorToasts = store.getState().ui.errors;
      const visibleErrorToasts = errorToasts.filter((error) => error.visible);
      const connectionErrorIsAlreadyDisplayed = visibleErrorToasts.some(
        (error) =>
          error?.message?.includes('Network Error') || error?.message?.includes('NotAuthorized')
      );
      testResult = connectionErrorIsAlreadyDisplayed;
    }
  }
  return testResult;
};
const handleHttpErrorResponseAndReturnDataForStore = (error: any): ErrorToast => {
  const errorToast: ErrorToast = {};
  if (error instanceof AxiosError) {
    if (error?.request?.data) {
      errorToast.message = JSON.stringify(error.request.data);
      errorToast.method = error?.config?.method;
      errorToast.url = String(error?.config?.baseURL) + String(error?.config?.url);
      errorToast.headers = JSON.stringify(error.request.headers);
      errorToast.params = JSON.stringify(error?.config?.params);
    } else if (error.response?.data) {
      errorToast.message = JSON.stringify(error.response.data);
      errorToast.status = error.response.status;
      errorToast.method = error?.config?.method;
      errorToast.description = JSON.stringify(error.response.data?.description);
      errorToast.url = String(error?.config?.baseURL) + String(error?.config?.url);
      errorToast.headers = JSON.stringify(error.response.headers);
      errorToast.params = JSON.stringify(error?.config?.params);
    } else if (!error?.response?.data && !error.request.data) {
      errorToast.url = String(error?.config?.baseURL) + String(error?.config?.url);
      errorToast.method = error?.config?.method;
      errorToast.message = error.message;
      if (error.message.includes('Network Error')) {
        errorToast.message = 'Network Error, Application is unable to connect to the server.';
      }
    }
  } else {
    errorToast.message = JSON.stringify(error.message);
  }
  if (error.config.url?.includes('login')) {
    errorToast.params = undefined;
    errorToast.headers = undefined;
  }
  return errorToast;
};
const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  store.dispatch(uiActions.enableSubmitButton());
  console.error(`[request error] [${JSON.stringify(error)}]`);
  const errorToast: ErrorToast = handleHttpErrorResponseAndReturnDataForStore(error);
  if (!connectionErrorIsAlreadyDisplayed(errorToast.message)) {
    store.dispatch(uiActions.openErrorToast(errorToast));
  }

  return Promise.reject(error);
};
const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  store.dispatch(uiActions.enableSubmitButton());
  console.error(`[response error] [${JSON.stringify(error)}]`);
  const errorToast: ErrorToast = handleHttpErrorResponseAndReturnDataForStore(error);
  if (!connectionErrorIsAlreadyDisplayed(errorToast?.message)) {
    store.dispatch(uiActions.openErrorToast(errorToast));
  }
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  const responseData = response.data;
  const { url } = response.config;
  const { method } = response.config;
  const isSuccessful = response.status >= 200 && response.status < 300;
  if (method && method.toUpperCase() !== 'GET' && isSuccessful) {
    store.dispatch(uiActions.openSuccessToast({ isFormAction: false }));
  }
  /* 
  if (method && method.toUpperCase() == 'POST') {
    store.dispatch(uiActions.enableSubmitButton())
  } */

  return response;
};

const backupperAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "x-api-key": process.env.REACT_APP_API_KEY,
  }
});
backupperAPI.interceptors.request.use(null, onRequestError);
backupperAPI.interceptors.response.use(onResponse, onResponseError);
export { backupperAPI };
